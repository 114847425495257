
































.iframe-kb {
  min-height: calc(100vh - 112px + 24px);
  border: none;
  margin: -24px;
  width: calc(100% + 50px);
}
